<template>
  <v-container>
      <br><br>
      <v-row>
          <v-col v-if="listData.length == 0"  cols="12" md="6">
              <v-container id="app">


                  <h1>Sube Archivo XML</h1>
                  <div v-if="!file">
                      <div :class="['dropZone', dragging ? 'dropZone-over' : '']" @dragenter="dragging = true" @dragleave="dragging = false">
                      <div class="dropZone-info" @drag="onChange">
                          <span class="fa fa-cloud-upload dropZone-title"></span>
                          <span class="dropZone-title">Arrastre o Seleccione Su Archivo XML</span>
                          <div class="dropZone-upload-limit-info">
                          <div style="color:orange">Extension Soportada: XML</div>
                  
                          </div>
                      </div>
                      <input type="file" accept="text/xml" @change="onChange">
                      </div>

                        <br>


                  </div>
  
                  <div v-else class="dropZone-uploaded">
                      <div class="dropZone-uploaded-info">
                      <span class="dropZone-title">Archivo Seleccionado!</span>
                      <v-btn color="red" type="button" class="btn btn-primary removeFile" @click="removeFile">Remover Archivos</v-btn>
                      </div>
                      <div style="color:orange" class="uploadedFile-info">
                      <small>Nombre Archivo: {{ file.name }}</small><br>
                      <small>Tamano(bytes): {{ file.size }}</small><br>
                      <small>Extension: {{ extension }}</small>
                  </div>
                  </div>

  
              </v-container>
          </v-col>
          <v-col v-if="listData.length == 0"  cols="12" md="6">
          
            <h2>Archivos disponibles para recibir</h2>

                                          
              <v-list lines="one">
                <v-list-item
                v-for="a in archivosdisponibles" v-bind:key="a"

                  :title="a.nombre"
                  :subtitle="a.date"
                >
                <v-btn size="x-small" icon="mdi-check" color="green" @click="chargeXML(a.id, a.archivo, a.nombre); overlay = true"></v-btn>
                <v-btn size="x-small" icon="mdi-trash-can" color="red" @click="deleteXML(a.id, a.archivo, a.nombre)"></v-btn>
                </v-list-item>
              </v-list>
          
          </v-col>
          <v-col v-if="listData.length > 0" cols="12" md="8">
              <h2>Lista de Productos   Fac.{{ file.name.split('.')[0] }}</h2>
              <v-icon @click="listData = []; totalArticulos = null; file=null" color="error" icon="mdi-cancel"></v-icon>
              
           <div ref="tablaContainer">
          
      
              <v-table
              style="overflow-y: auto; max-height: 500px;"
              >
                <thead>
                      <tr>
                      <th class="text-left">
                          Partida
                      </th>
                      <th class="text-left">
                          Barras
                      </th>
                      <th class="text-left">
                          Codigo
                      </th>
                      <th class="text-left">
                          Descripcion
                      </th>
                      <th class="text-left">
                          Cantidad Facturada
                      </th>
                      <th class="text-left">
                          Cantidad Recibida
                      </th>
                      <th class="text-left">
                          Opciones
                      </th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr
                     
                      v-for="( p, index) of listData" v-bind:key="index"
                      :class="{'fila-resaltada': Number(p.cantidad) === Number(p.capturado), 'fila-oculta': hideHighlightedRows && Number(p.cantidad) === Number(p.capturado) }"
                      
                      >

                      
                      <td>{{ index+1}}</td>
                      <td>{{ p.codigo}}</td>
                      <td>{{ p.sku}}</td>
                      <td>{{ p.descripcion }}</td>
                      <td style="color:red" ><strong>{{ p.cantidad }}</strong></td>
                      <td style="color:green" ><strong>{{ p.capturado }}</strong></td>
                      <td>   <v-icon
                              @click="reloadqty(index)"
                              color="dark darken-2"
                              >
                              mdi-reload
                              </v-icon>
                      </td>
                      </tr>
                  </tbody>
              </v-table>
      

            
          </div>
          </v-col>
          <v-col v-if="listData.length > 0" cols="12" md="4">
              <br><br>
              <v-text-field
               v-model="codigoentrante"
               filled
               autofocus
               v-on:keyup.enter="validarcodigo()"
               label="Escanne el codigo del producto"
              ></v-text-field>

              <br><br>
              <strong>No. Partidas:.....{{this.listData.length}}</strong><br>
              <strong>{{this.totalArticulos}} Articulos Facturados</strong><br>
              <strong>{{this.totalArticulosCheck}} Articulos Revisados</strong><br><br>

    
              <v-progress-circular 
              :color="getcolor()"
              :size="100"
              
              :model-value="checkPorcentaje" 
              :width="20"> {{ checkPorcentaje }}</v-progress-circular><br><br>


              <v-btn color="success" @click="generarPDF()">Imprimir Acuse</v-btn><br><br>
              <button @click="toggleHideHighlightedRows">Ocultar Completos</button>



          </v-col>
      </v-row>
  
      <v-overlay :value="overlay">
              <v-progress-circular
                  indeterminate
                  size="64"
              ></v-progress-circular>
          </v-overlay>
  
  

  <v-overlay v-model="overlay"></v-overlay>
  
  </v-container>
  
  
  
  
  
  
  </template>
  
  
  <script>
  import axios from 'axios';

  import jsPDF from 'jspdf';
  import autoTable from 'jspdf-autotable'
  export default {
  
      data(){
          return {
              overlay: false,
              file: '',
              dragging: false,
              listData: [],
              archivosdisponibles: [],
              productsStocked: [],
              dialogResults: false,
              codigoentrante: null,
              totalArticulos: 0,
              totalArticulosCheck: 0,
              checkPorcentaje: 0,
              gradiente: ["primary lighten-2", "primary lighten-4"],
              hideHighlightedRows: false,
         
          }
      },
      
    created(){
        this.getLastOrders()
    } , 
 
    methods: {
      getLastOrders(){
        axios.get("api/getLastOrdersXML/527521").then(response =>{
          console.log(response.data)
          this.archivosdisponibles = response.data
        })
      },
      onChange(e) {
        var files = e.target.files || e.dataTransfer.files;
        
        if (!files.length) {
          this.dragging = false;
          return;
        }
        
        this.createFile(files[0]);
  
      },
      createFile(file) {
        if (!file.type.match('text.*')) {
          alert('please select txt file');
          this.dragging = false;
          return;
        }
        
        if (file.size > 5000000) {
          alert('please check file size no over 5 MB.')
          this.dragging = false;
          return;
        }
        
        this.file = file;
        console.log(this.file);
        this.dragging = false;
        this.uploadXML()
      },
      removeFile() {
        this.file = '';
      },
      deleteXML(id){
          axios.delete("api/orderXML/"+id).then(response => {
           if(response.data.affectedRows === 1){
              alert("se elimino")
              this.getLastOrders()
           }
          })
      },
      chargeXML(id, file, nombre){
         this.file =  new File([file.data], nombre, { type: 'text/xml' });
          axios.get("api/idXMLgeneratelistXML/"+id).then(response => {
            console.log(response)
            this.listData =  response.data
          
            this.totalArticulos = this.listData.reduce((acumulador, elemento) => acumulador + Number(elemento.cantidad), 0);
         
            this.overlay = false;
          })

      },
      uploadXML(){
          // const file = this.file[0]
         
           this.overlay = true;
            axios.post('https://public.ferri.app/xml/index.php', {file: this.file}, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
            }).then(response => {
              this.generateList(response.data.location)
              this.overlay = false;
              // this.$notify({
              //                 type: 'success',
              //                 group: 'foo',
              //                 title: 'Se Cargo',
              //                 text: "Se Cargo Correctamente"
              //             })

              alert("se cargo")
            })
      },
      generateList(file){
        console.log(file)
          axios.post("api/stock/1/generatelistXML", {file: file}).then(response => {
              this.listData =  response.data
          
              this.totalArticulos = this.listData.reduce((acumulador, elemento) => acumulador + Number(elemento.cantidad), 0);

              // var combinados = {};

              //     this.listData.forEach(function(item) {
              //       if (!combinados[item.codigo]) {
              //         combinados[item.codigo] = item;
              //       } else {
              //         combinados[item.codigo].cantidad = String(
              //           parseInt(combinados[item.codigo].cantidad) + parseInt(item.cantidad)
              //         );
              //       }
              //     });

              //     // Convertir el objeto resultante en una matriz
              //     this.listData = Object.values(combinados);

             



          })
      },

      getTotalCheck(){
          this.totalArticulosCheck = this.listData.reduce((acumulador, elemento) => {
              if (elemento.capturado !== undefined) {
                  return Number(acumulador) + Number(elemento.capturado);
              }
              return Number(acumulador);
              }, 0);

           this.checkPorcentaje = ((Number(this.totalArticulosCheck) / Number(this.totalArticulos)) * 100).toFixed(2)
      },
      validarcodigo(){
         var code = this.codigoentrante
         var lengthcode = code.length

         switch (lengthcode) {
              case 5:
                  // alert("es un codigo SKU")
                  axios.get("api/getean/"+code).then(response => {
                      console.log(response.data)
                       if(response.data === "no se encontro codigo"){
                        var audio2 = new Audio('https://public.ferri.app/noreconocido.m4a'); // Cambia la ruta al archivo de audio
                        audio2.play();
                        this.codigoentrante = ""
                       }else{
                            this.intocode(response.data.ean+"", response.data.cantidad, response.data.codigo, true) 
                       }
                       
                      
                       
                  })
             

                  
              break;
              case 6:
                   axios.get("api/getean/"+code).then(response => {
                    if(response.data === "no se encontro codigo"){
                      var audio2 = new Audio('https://public.ferri.app/noreconocido.m4a'); // Cambia la ruta al archivo de audio
                      audio2.play();
                      this.codigoentrante = ""
                    }else{
                      this.intocode(response.data.ean+"", response.data.cantidad, response.data.codigo, true)  
                    }
                      
                       
                   })
              break;
              case 13:
                    axios.get("api/getean/"+code).then(response => {
                      if(response.data === "no se encontro codigo"){
                        var audio2 = new Audio('https://public.ferri.app/noreconocido.m4a'); // Cambia la ruta al archivo de audio
                        audio2.play();
                        this.codigoentrante = ""
                      }else{
                          this.intocode(response.data.ean+"", response.data.cantidad, response.data.codigo)  
                      }    
                       
                   })
              break;
              case 14:
              axios.get("api/getean/"+code).then(response => {
                if(response.data === "no se encontro codigo"){
                        var audio2 = new Audio('https://public.ferri.app/noreconocido.m4a'); // Cambia la ruta al archivo de audio
                      audio2.play();
                      this.codigoentrante = ""
                    }else{
                        this.intocode(response.data.ean+"", response.data.cantidad, response.data.codigo)  
                    }    
               })
              break;
              default:
                 
                 var audio2 = new Audio('https://public.ferri.app/noreconocido.m4a'); // Cambia la ruta al archivo de audio
                 audio2.play();
                 this.codigoentrante = ""
          }
      },

      getean(code){
        return  axios.get("api/getean/"+code).then(response => {
              console.log(response.data)
              return response.data
          })
      },
      getcolor(){
        if(this.checkPorcentaje < 20){
          return "red"
        }
        if(this.checkPorcentaje > 21){
          return "orange"
        }
        if(this.checkPorcentaje > 80){
          return "red"
        }
      },

      intocode(code, cantidad, sku, isSku){
          let encontrado = false;
          let estado = ""
          console.log(sku)
          for (let i = 0; i < this.listData.length; i++) {
              if (this.listData[i].codigo === code) {
                  this.listData[i].sku = sku
                  if(this.listData[i].capturado){
                      if(this.listData[i].capturado < this.listData[i].cantidad){
                          if(isSku === true){
                           let qtySku = prompt("Cantidad de Productos con este Codigo :")
                           this.listData[i].capturado = this.listData[i].capturado+Number(qtySku);
                          }else{
                            this.listData[i].capturado = this.listData[i].capturado+cantidad;
                          }
                          
                          
                          
                      }else{
                          this.codigoentrante = ""
                          const audio = new Audio('https://public.ferri.app/completo.m4a'); // Cambia la ruta al archivo de audio
                          audio.play();
                          estado = "completo"
                      }
                      
                  }else{
                    if(isSku === true){
                           let qtySku = prompt("Cantidad de Productos con este Codigo :")
                           this.listData[i].capturado = Number(qtySku);
                          }else{
                            this.listData[i].capturado = cantidad
                          }
                      // this.listData[i].capturado = cantidad;
                  }
                  encontrado = true;
                break;
                  
              }

              
          }



          
          if (!encontrado) {
              // Objeto no encontrado, agrega el nuevo objeto al final
              this.codigoentrante = ''
              const audio = new Audio('https://public.ferri.app/error.wav'); // Cambia la ruta al archivo de audio
              audio.play();
              setTimeout(() => {
                
                const audio2 = new Audio('https://public.ferri.app/noorden.m4a'); // Cambia la ruta al archivo de audio
                 audio2.play();
                
       

              }, 1000);
             
          }else{
              if(estado !== "completo"){
                const audio = new Audio('https://public.ferri.app/succes.wav'); // Cambia la ruta al archivo de audio
                audio.play();
              }
             
              this.codigoentrante = ''
              var array = this.listData;
              this.listData = []
              this.listData = array
              this.getTotalCheck()
          }
      },
      imprimirTabla() {
          const printWindow = window.open('', '_blank');
          const content = this.$refs.tablaContainer.innerHTML;
          printWindow.document.write('<html><head><title>Tabla</title></head><body>');
          printWindow.document.write(content);
          printWindow.document.write('</body></html>');
          printWindow.document.close();
          printWindow.print();
      },

      reloadqty(index){
          let respuesta = confirm("¿Estás seguro de que deseas continuar?");
          if (respuesta) {
              this.listData[index].capturado = 0
              var array = this.listData;
              this.listData = []
              this.listData = array
              this.getTotalCheck()
          }
         
      },
      toggleHideHighlightedRows() {
        this.hideHighlightedRows = !this.hideHighlightedRows;
     
      },  


      generarPDF() {
          var fechaActual = new Date();
          var fechaTexto = fechaActual.toLocaleDateString();
          const pdf = new jsPDF();

          pdf.setFontSize(6); // Smaller font size
          pdf.text(fechaTexto, 20, 10);
          pdf.text(this.file.name, 80, 10);

          const headers = [["#", "Barras", "Codigo", "Descripcion", "Cantidad Facturada", "Cantidad Chequeada", "Status"]];

          var data = this.listData.map(function(row, index) {
              var complete = ""
              if(row.capturado == row.cantidad){
                complete = "COMPLETADO "
              }
              return [index + 1, row.codigo, row.sku, row.descripcion, row.cantidad, row.capturado, complete];
          });

          autoTable(pdf, {
              head: headers,
              body: data,
              startY: 20,
              theme: 'striped',
              styles: {
                  cellPadding: 1,
                  fontSize: 5, // Smaller font size
                  rowHeight: 5, // Smaller row height
                  halign: 'center',
                  valign: 'middle',
                  lineWidth: 0.05, // Thinner line width
              },
              headStyles: {
                  fillColor: [200, 200, 200],
                  textColor: 0,
              },
              margin: { top: 10 },
              didDrawCell: function(data) {
                  if (data.row.index === data.table.body.length) {
                      // Draw a horizontal line at the bottom of the table
                      pdf.setLineWidth(0.1); // Adjusted line width
                      pdf.line(data.table.pageStartX, data.table.pageStartY + data.table.height, data.table.pageStartX + data.table.width, data.table.pageStartY + data.table.height);
                  }
              },
          });

          pdf.save('tabla.pdf');

      }
  
  
  
    },
    computed: {
      extension() {
        return (this.file) ? this.file.name.split('.').pop() : '';
      }
    }
    
  
  }
  
  
  </script>
  
  
  <style>
  .dropZone {
    width: 100%;
    height: 200px;
    position: relative;
    border: 2px dashed #eee;
  }
  
  .dropZone:hover {
    border: 2px solid #2e94c4;
  }
  
  .dropZone:hover .dropZone-title {
    color: #1975A0;
  }
  
  .dropZone-info {
    color: #A8A8A8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
  }
  
  .dropZone-title {
    color: #787878;
  }
  
  .dropZone input {
    position: absolute;
    cursor: pointer;
    top: 0px;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
  
  .dropZone-upload-limit-info {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }
  
  .dropZone-over {
    background: #5C5C5C;
    opacity: 0.8;
  }
  
  .dropZone-uploaded {
    width: 100%;
    height: 200px;
    position: relative;
    border: 2px dashed #eee;
  }
  
  .dropZone-uploaded-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #A8A8A8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
  }
  
  .removeFile {
    width: 200px;
  }



  .fila-resaltada {
        background-color: rgba(0, 255, 89, 0.443); /* Color de fondo para las filas resaltadas */

   }

   .fila-oculta {
       display: none;
   }

  .incompleto{
      background-color: rgba(255, 0, 0, 0.312); /* Color de fondo para las filas resaltadas */
  }
  </style>